import { render, staticRenderFns } from "./KeyValue.vue?vue&type=template&id=1b4b10e5&scoped=true&lang=pug"
import script from "./KeyValue.vue?vue&type=script&lang=ts"
export * from "./KeyValue.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b4b10e5",
  null
  
)

export default component.exports