
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"

  export default defineComponent({
    name: 'cr_create',
    props: {
      lead_id: {
        type: String,
        required: true
      },
      show: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const newCr = {firstName: '', lastName: ''}

      const submitCr = async () => {
        showLoading.value = true
        const data = { id: props.lead_id, body: { attributes: newCr, usecase: 'cr_create' } }
        root.$store.dispatch('leads/edit', data)
          .then(()=>showLoading.value = false)
          .catch(()=>showLoading.value = false)

      }

      return {
        showLoading,
        submitCr,
        newCr
      }
    }
  })
