import { resourceUrl } from "@/utils/dataExtractors"
export const leadHouseholdsColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    type: "Def",
    key: "attributes.designation",
    title: "Haushalt",
    sortable: true,
    options: {
      // contentFunction: (data:any) => {
      //   console.log({data})
      //   return data.item.attributes.designation
      // },
      hrefFunction: (data: any) => resourceUrl(data.item)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.careReceivers",
    title: "# PBs",
    optioins: {
      contentFunction: (data: any) => data.item.attributes.care_receivers.length
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.dormant",
    title: 'Aktiv'
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.archived",
    title: 'Archiviert'
  },
]