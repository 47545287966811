import { provide, inject } from 'vue'

export const RefreshDataKey = Symbol()

export function provideRefreshData(refreshData: () => void) {
  provide(RefreshDataKey, refreshData)
}

export function getRefreshData() {
  const injection = inject<() => void>(RefreshDataKey)
  if (!injection) throw new Error("No refreshData function provided")
  return injection
}