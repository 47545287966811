
  import { defineComponent, reactive, ref, computed, getCurrentInstance } from "vue"
  // import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  export default defineComponent({
    props: {
      data: {
        type: Array,
        default: () => ([])
      },
      readOnly: {
        type: Boolean,
        default: () => false
      }
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const nameInputElement = ref<any>(null)
      const newPair = ref(['', ''])
      const removePair = (idx: number) => {
        props.data.splice(idx, 1)
        emit('changed')
      }
      const addPair = () => {
        if (newPair.value[0]===''||newPair.value[1]==='') return
        props.data.push(newPair.value)
        newPair.value = ['', '']
        emit('changed')
        nameInputElement.value.focus()
      }

      return {
        addPair,
        removePair,
        newPair,
        nameInputElement
      }
    }
  })
