
  import { defineComponent, reactive, ref, computed, watch, getCurrentInstance } from "vue"
  import FormStepper from "@/components/shared/FormStepper.vue"
  import { Field } from '@/models/care_receivers'
  import { Datetime } from 'vue-datetime'
  import { getRefreshData } from "@/utils/refreshData";
  import { getMissingFields } from "@/utils/careReceiverHelper"
  import { getKeycloak } from "@/plugins/keycloak";

  // import { CollectionResourceDoc } from '@/models/jsonapi'
  // interface Props {
  //   careReceiver: <CollectionResourceDoc<string, CareReceiver>>
  // }

  export default defineComponent({
    components: {
      FormStepper
    },
    props: {
      careReceiver: {
        type: Object,
        required: true,
      }
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const keycloakRef = getKeycloak()
      const role = computed(() => keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0])

      const componentPicker = (type: string) => {
        return {
          input: 'BFormInput',
          radio: 'BFormRadioGroup',
          mc: 'BFormCheckboxGroup',
          date: Datetime,
        }[type]
      }

      const optionsPicker = (field: Field) => {
        if (field.type === 'mc') {
          const opts:Array<string> = []
          for (let key in field.options) {
            opts.push(field.options[key])
          }
          return opts
        } else if (field.type === 'radio') {
          return field.options
        } else {
          return []
        }
      }

      const pages = computed(() => root.$store.state.care_receivers.pages)
      watch(pages, (newValue) => {
        const reducer = (res, srcPage) => {
          const formData = {}
          const pageFields = srcPage.fields.map( (f) => {
            formData[f.key] = props.careReceiver.attributes[f.key]
            return {
              ...f,
              component: componentPicker(f.type),
              options: optionsPicker(f),
            }
          })
          res['formOptions'].push({
            title: srcPage.pageTitle.value,
            slot: srcPage.pageTitle.short,
            options: pageFields,
          })
          res['form'][srcPage.pageTitle.short] = {
            ...res['form'][srcPage.pageTitle.short],
            ...formData
          }
          return res
        }
        const tmpCarrier = newValue.reduce(reducer, { form: {}, formOptions: [] })
        formOptions.value = tmpCarrier.formOptions
        form.value = tmpCarrier.form
      })
      const form = ref()
      const formOptions = ref()
      const missingFields = computed<{ [key:string]: Field[] }>(() => {
        if (!form.value) return {}
        return Object.keys(form.value).reduce((result, key) => {
          const sectionFields = formOptions.value.find(formOpt => formOpt.slot === key).options
          result[key] = getMissingFields(sectionFields, form.value[key])
          return result
        }, {})
      })

      root.$store.dispatch('care_receivers/get_pages')

      const refreshData = getRefreshData()
      const sendForm = async () => {
        if (role.value === 'provider') return
        const data = formDataToApi(form.value)
        await root.$store.dispatch('care_receivers/edit', { id: props.careReceiver.id, body: data })
        refreshData()
      }

      const formDataToApi = (data: any) => {
        const cr = {
          id: props.careReceiver.id,
          type: 'care_receivers',
          attributes: {},
        }
        const red = (res, pageData) => {
          Object.entries(pageData).forEach((pair) => {
            res.attributes[pair[0]] = pair[1]
          })
          return res
        }
        return Object.values(data).reduce(red, cr)
      }

      const checkDependency = (option: any, sectionKey: string) => {
        if (option.dependency) {
          const dependencyKey = option.dependency.key
          const dependencyValue = option.dependency.value
          const sectionFormValue = form.value[sectionKey]
          // Dependency value is an array of possible choice or a single value
          if (sectionFormValue[dependencyKey] === dependencyValue || (Array.isArray(dependencyValue) && dependencyValue.includes(sectionFormValue[dependencyKey]))) {
            return true
          }
          return false
        }
        return true
      }

      const getButtonVariant = (index: number, currentStep: number) => {
        if (missingFields.value[formOptions.value[index].slot].length > 0 && currentStep === index) return 'outline-warning'
        if (missingFields.value[formOptions.value[index].slot].length > 0) return 'warning'
        if (currentStep === index) return 'primary'
        return 'secondary'
      }

      return {
        form,
        formOptions,
        sendForm,
        checkDependency,
        missingFields,
        getButtonVariant
      }
    }
  })
