
  import { Lead } from "@/models/leads";
  import { Household } from "@/models/households";
  import { CareReceiver } from "@/models/care_receivers";
  import { Leadsource } from "@/models/leadsources";
  import { User } from "@/models/users";
  // import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue";
  import Logs from "@/components/shared/Logs.vue";
  import Address from "@/components/shared/Address.vue";
  import AddressCard from "@/components/shared/AddressCard.vue";
  import Erfassungsbogen from "@/components/shared/CareReceiverEdit.vue"
  import { defineComponent, ref, watch, computed, reactive, getCurrentInstance } from "vue"
  import { provideRefreshData } from '@/utils/refreshData'
  import { resourceUrl, fullName } from '@/utils/dataExtractors'
  import { confirm, prompt } from '@/utils/interactionModals'
  import { apiDataMorpher, apiDataMerger, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { CollectionResourceDoc, SingleResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { Log } from "@/models/logs"
  import moment from 'moment'
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import Tickets from "@/components/shared/Tickets.vue"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import HtmlElement from "@/components/shared/form/elements/html/HtmlElement.vue"
  import CrCreate from "@/components/shared/CareReceiverCreate.vue"
  import KeyValue from "@/components/shared/KeyValue.vue"
  import Tasks from "@/components/shared/Tasks.vue"
  import LeadKeyData from "@/components/seller/LeadKeyData.vue"
  import { leadHouseholdsColumns } from "@/view_scripts/table_columns/household_columns"

  interface Props {
    id: string
  }

  interface selectOption {
    value: string|null|undefined,
    text?: string
  }

  export default defineComponent({
    components: {
      Address,
      AddressCard,
      Logs,
      Erfassungsbogen,
      // TitleActionsNavbar,
      TicketEdit,
      Tickets,
      SchemaForm,
      HtmlElement,
      CrCreate,
      KeyValue,
      LeadKeyData,
      Tasks
    },
    name: "seller_lead_show",
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const showLoading = ref<boolean>(false)
      const lead = computed<ResourceObject<string, Lead>>(() => root.$store.state.leads.element)
      const leadResponse = computed(() => root.$store.state.leads.elementResponse)
      const households = computed<ResourceObject<string, Household>[]>(() => root.$store.state.households.data)
      const freeCrs = computed<ResourceObject<string, CareReceiver>[]>(() => {
        return root.$store.state.care_receivers.data.filter((cr: ResourceObject<string, CareReceiver>) => {
          return (cr.relationships?.household as RelationshipsWithData)?.data === undefined
        })
      })

      const hhOptions = computed<selectOption[]>(() => {
        const defaultOptions: selectOption[] = [
          {value: undefined, text: 'Bitte einen Haushalt wählen'},
          {value: 'Neu', text: 'Neuen Haushalt erstellen'}
        ]
        const options: selectOption[] = households.value.map((hh:ResourceObject<string, Household>) => {
          return { value: hh.id, text: hh.attributes?.designation }
        })
        return defaultOptions.concat(options)
      })
      const newTicketModal = ref()
      const careReceiverId = ref<string>()
      const ticketIds = ref<string[]>([])
      const leadProcessModal = ref()
      const sendMailModal = ref()
      const crCreateModal = ref(false)
      const processLeadData = reactive<{[key: string]: any}>({
        reasons: [],
        customReason: '',
        selectedReason: null,
      })
      const mailData = reactive({
        title: '',
        type: '',
        manualText: '',
        hasManualText: false,
        test: false,
      })
      const leadDefaultMail = computed(() => {
        const mails = (lead.value.attributes?.address?.attributes?.emails || [])
          .filter((m) => {return m.default})
        if (mails.length == 1) {return mails[0].email} else {
          return 'Keine oder uneindeutige default email gefunden'
        }
      })

      const refreshData = () => {
        root.$store.dispatch('leads/get', { id: props.id, queryParams: { include: 'address,proto_leadsource,care_receivers,seller' } })
        root.$store.dispatch('tasks/load', { 'filter[taskable_id]': props.id, include: 'action_type' } )
      }

      watch(leadResponse, (newValue) => {
        ticketIds.value = extractRelationshipIds(newValue, 'tickets')
        const hhIds = extractRelationshipIds(newValue, 'households').join(',')
        root.$store.dispatch('households/load', {'filter[id]': hhIds, include: 'care_receivers'})
        getFreeCareReceiver(props.id)
      })

      const getFreeCareReceiver = async (leadId: string) => {
        const crQuery = {'filter[lead_id]': leadId}
        await root.$store.dispatch('care_receivers/load', crQuery)
      }

      provideRefreshData(refreshData)
      refreshData()

      const titleActions = ref([
        {
          title: 'PB erstellen',
          icon: 'user-plus',
          tooltip: 'Eine neue pflegebedüftige Person hinzufügen',
          onClick: () => crCreateModal.value = true
        },
        newTicketAction(newTicketModal),
        {
          title: 'Aktionen',
          // icon: 'archive',
          tooltip: 'Transaktionen mit dem Lead',
          children: [
            {
              title: 'Google Kontakt angleichen',
              // icon: 'archive',
              // tooltip: 'Lead archivieren',
              onClick: async () => {
                const msg = "Die Aktuelle Adress in die Google Kontakte übernehmen?"
                const confirmed = await confirm(root, msg)
                if (confirmed) updateLead({usecase: 'update_google_contact'})
              },
            },
            {
              title: 'Archivieren',
              // icon: 'archive',
              // tooltip: 'Lead archivieren',
              onClick: () => archiveLead(),
            },
            {
              title: 'Reklamieren',
              // icon: 'exclamation-triangle',
              // tooltip: 'Lead Reklamieren',
              onClick: () => reclaimLead()
            },
            {
              title: 'Lead Absage',
              // icon: 'exclamation-triangle',
              // tooltip: 'Lead Reklamieren',
              onClick: () => cancelLead()
            },
            {
              title: 'Lead erreicht markieren',
              // icon: 'exclamation-triangle',
              tooltip: 'Lead als erreicht markieren.',
              onClick: () => leadContacted()
            },
            {
              title: 'Nicht erreicht Logeintrag',
              // icon: 'exclamation-triangle',
              tooltip: 'Logeintrag erstellen, dass der Lead erfolglos kontaktiert worden ist.',
              onClick: () => logContactAttempt()
            }
          ]
        },
        {
          categoryTitle: 'Mails',
          title: 'Mails',
          tooltip: 'E-Mails an den Kunden',
          children: [
            {
              title: 'Kontaktiert (warten)',
              onClick: () => {
                mailData.title = 'Kontaktiert (warten)'
                mailData.type = 'contacted_pending'
                mailData.hasManualText = true
                sendMailModal.value.show()
              }
            },
            {
              title: 'Kontaktiert (gib EB)',
              onClick: () => {
                mailData.title = 'Kontaktiert (gib EB)'
                mailData.type = 'contacted_without_eb'
                mailData.hasManualText = true
                sendMailModal.value.show()
              }
            },
            {
              title: 'Kontaktiert (hab EB)',
              onClick: () => {
                mailData.title = 'Kontaktiert (hab EB)'
                mailData.type = 'contacted_with_eb'
                mailData.hasManualText = true
                sendMailModal.value.show()
              }
            },
            {
              title: 'Nicht erreicht (Nummer?)',
              onClick: () => {
                mailData.title = 'Nicht erreicht (Nummer?)'
                mailData.type = 'not_reached_wrong_tel'
                mailData.hasManualText = false
                sendMailModal.value.show()
              }
            },
            {
              title: 'Nicht erreicht (gib EB)',
              onClick: () => {
                mailData.title = 'Nicht erreicht (gib EB)'
                mailData.type = 'not_reached_without_eb'
                mailData.hasManualText = false
                sendMailModal.value.show()
              }
            },
            {
              title: 'Nicht erreicht (hab EB)',
              onClick: () => {
                mailData.title = 'Nicht erreicht (hab EB)'
                mailData.type = 'not_reached_with_eb'
                mailData.hasManualText = false
                sendMailModal.value.show()
              }
            },
            {
              title: '7 Tage (gib EB)',
              onClick: () => {
                mailData.title = '7 Tage (gib EB)'
                mailData.type = 'seven_days_without_eb'
                mailData.hasManualText = false
                sendMailModal.value.show()
              }
            },
            {
              title: '7 Tage (hab EB)',
              onClick: () => {
                mailData.title = '7 Tage (hab EB)'
                mailData.type = 'seven_days_with_eb'
                mailData.hasManualText = false
                sendMailModal.value.show()
              }
            },
          ]
        }
      ])


      const assignHousehold = async (cr: ResourceObject<string, CareReceiver>) => {
        if (cr.id) {
          const data = {
            attributes: { householdId: cr.attributes?.householdId },
            usecase: 'household_assign'
          }
          await root.$store.dispatch('care_receivers/edit', { id: cr.id, body: data })
          refreshData()
        }
      }

      // const leadsources = ref<CollectionResourceDoc<string, Leadsource>>()
      // const leadsourceQueryParams = {
      //   'filter[_type]': 'leadsource'
      // }
      // LeadsourcesRepository.list(leadsourceQueryParams).then(response => leadsources.value = response)

      const updateLead = async (data: any) => {
        showLoading.value = true
        try {
          await root.$store.dispatch('leads/edit', { id: props.id, body: data })
          refreshData()
        } finally {
          showLoading.value = false
        }
      }

      const archiveLead = async () => {
        if (lead.value.attributes?.reclaimed) {
          prompt(root, "Reklamierte Leads können nur vom Innendienst archiviert werden")
        } else {
          const msg = "Ein archivierter Lead kann nicht mehr reklamiert werden. Die Archivierung kann nur vom Innendienst rückgängig gemacht werden."
          const decision = await confirm(root, msg)
          if (decision) {
            updateLead({usecase: 'archive_lead'})
          }
        }
      }

      const reclaimLead = async () => {
        if (lead.value.attributes?.archived) {
          prompt(root, "Archivierte Leads können nicht Reklamiert werden.")
        } else if (lead.value.attributes?.reclaimed) {
          prompt(root, "Dieser Lead ist bereits reklammiert.")
        } else {
          processLeadData.usecase = 'reclaim_lead'
          processLeadData.title = 'Lead reklamieren'
          processLeadData.text = 'Der Lead wird als Reklamation gemeldet. Diese Aktion kann nicht rückgängig gemacht werden.'
          processLeadData.reasons = []
          const listQuery = {'filter[sys_id]': 'lead_reclaim_reasons',include: 'sublists'}
          await root.$store.dispatch('lists/load', listQuery)
          leadProcessModal.value.show()
          const tmp = root.$store.state.lists.data[0].attributes.sublists.map((rr)=>{
            return { value: rr.id, text: rr.attributes?.name }
          })
          // }).concat({value: 'new', text: "Eigene Begründung angeben..."})
          processLeadData.reasons = processLeadData.reasons.concat(tmp)
          processLeadData.reasons.unshift({value: null, text: 'Reklamationsgrund wählen'})
        }
      }

      const cancelLead = async () => {
        if (lead.value.attributes?.archived) {
          prompt(root, "Dieser Lead ist bereits archiviert.")
        } else if (lead.value.attributes?.reclaimed) {
          prompt(root, "Dieser Lead ist bereits reklammiert.")
        } else {
          processLeadData.usecase = 'cancel_lead'
          processLeadData.title = 'Lead absagen'
          processLeadData.text = 'Der Lead wird archiviert. Ggf. wird die Stellenausschreibung deaktiviert. Ein laufender Vertrag muss vorher separat beendet werden.'
          processLeadData.reasons = []
          const listQuery = {'filter[sys_id]': 'lead_cancel_reasons',include: 'sublists'}
          await root.$store.dispatch('lists/load', listQuery)
          leadProcessModal.value.show()
          const tmp = root.$store.state.lists.data[0].attributes.sublists.map((rr)=>{
            return {value: rr.id, text: rr.attributes?.name}
          }).concat({value: 'new', text: "Eigene Begründung angeben..."})
          processLeadData.reasons = processLeadData.reasons.concat(tmp)
          processLeadData.reasons.unshift({value: null, text: 'Absagegrund wählen'})
        }
      }

      const leadContacted = async () => {
        if (lead.value.attributes?.contacted) {
          prompt(root, "Dieser Lead ist bereits als kontaktiert markiert.")
        } else {
          const msg = "Dem System mitteilen, dass der Lead erreicht worden ist?"
          const decision = await confirm(root, msg)
          if (decision) {
            updateLead({usecase: 'set_contacted'})
          }
        }
      }

      const logContactAttempt = async () => {
        const msg = "Für diesen Lead einen gescheiterten Kontaktversuch registrieren?"
        const decision = await confirm(root, msg)
        if (decision) {
          updateLead({usecase: 'log_contact_attempt'})
        }
      }

      const processLead = () => {
        if (processLeadData.selectedReason === null) return
        const data = {usecase: processLeadData.usecase, list_id: processLeadData.selectedReason, custom_reason: processLeadData.customReason}
        updateLead(data)
      }

      const sendMail = async () => {
        const data = {mail_data: mailData, usecase: 'send_mail'}
        showLoading.value = true
        try {
          await root.$store.dispatch('leads/edit', { id: props.id, body: data })
          refreshData()
        } finally {
          showLoading.value = false
        }
      }

      const submitTicket = () => {
        refreshData()
        newTicketModal.value.hide()
      }

      const postingInfo = computed(() => {
        return (households.value[0]?.relationships?.posting as RelationshipsWithData)?.data
      })

      return {
        postingInfo,
        lead,
        showLoading,
        updateLead,
        households,
        freeCrs,
        hhOptions,
        fullName,
        titleActions,
        refreshData,
        moment,
        resourceUrl,
        assignHousehold,
        newTicketModal,
        crCreateModal,
        submitTicket,
        leadProcessModal,
        sendMailModal,
        processLeadData,
        processLead,
        ticketIds,
        mailData,
        sendMail,
        leadHouseholdsColumns
      }
    }
  })
