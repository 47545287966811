
  import { defineComponent, computed } from "vue"
  // import { shortDate, fullName } from "@/utils/helper"
  import { ResourceObject } from '@/models/jsonapi'
  import { Lead } from "@/models/leads";
  import moment from 'moment'

  interface Props { lead: ResourceObject<string, Lead> }

  export default defineComponent({
    name: 'SellerLeadKeyData',
    props: {
      lead: {
        type: Object,
        required: true
      },
    },
    setup(props: Props) {
      // const present = (val) => val != null && val != undefined
      const la = computed(()=>props.lead.attributes)
      return {
        // shortDate,
        // present,
        la,
        moment
      }
    }
  })
